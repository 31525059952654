<template>
  <div class="hello" ref="canvasContainer">
    <!-- 这里是用于渲染的画布 -->
  </div>
</template>

<script>
import {
  Engine,
  Scene,
  ArcRotateCamera,
  HemisphericLight,
  Vector3,
  SceneLoader,
  Color3,
  PointLight,
  Tools,
  ShadowGenerator,
  MeshBuilder,
  StandardMaterial,
  SpriteManager, // 导入 SpriteManager
  Sprite,
  PointerEventTypes,
  //ScrollViewer,
  //Control
} from "@babylonjs/core";
import { AdvancedDynamicTexture, Rectangle, TextBlock,Button } from "@babylonjs/gui"; // 导入 GUI 相关类
import "@babylonjs/loaders"; // 确保加载器被导入

export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      advancedTexture: null, // 添加 advancedTexture 作为组件的属性
      mymin: null,
      mymax: null,
      alartX: 0,
      alartY: 0,
      alartZ: 0,
    };
  },
  mounted() {
    this.createScene();
  },
  methods: {
    showModalPopup() {
      // Create a full-screen advanced texture for the UI
      this.advancedTexture = AdvancedDynamicTexture.CreateFullscreenUI("UI");

      // Create the modal rectangle
      const modal = new Rectangle("modal");
      modal.width = 0.5; // 50% of the screen width
      modal.height = 0.5; // 50% of the screen height
      modal.background = "#FFFFFF"; // White background
      modal.color = "#000000"; // Border color
      modal.thickness = 2; // Border thickness
      modal.cornerRadius = 10; // Rounded corners
      modal.zIndex = 1000; // Ensure it appears above everything else
      this.advancedTexture.addControl(modal);

      // Add a title text block to the modal


      // Add description text block to the modal
      const description = new TextBlock();
      description.text = "这是一个弹窗";
      description.color = "black";
      description.fontSize = 16;
      description.paddingTop = "20px";
      description.textWrapping = true;
      modal.addControl(description);

      // Add a close button to the modal
      const closeButton = Button.CreateSimpleButton("closeButton", "Close");
      closeButton.width = "120px";
      closeButton.height = "40px";
      closeButton.color = "white";
      closeButton.background = "red";
      closeButton.cornerRadius = 5;
      closeButton.top = "40%"; // Position the button lower on the modal
      closeButton.onPointerUpObservable.add(() => {
        this.advancedTexture.removeControl(modal); // Remove the modal when close button is clicked
      });
      modal.addControl(closeButton); // Add the button to the modal
    },
    createLabel(mesh) {
      // 创建气泡标签
      var label = new Rectangle("label for 123456");
      label.background = "black";
      label.height = "30px";
      label.alpha = 0.5;
      label.width = "100px";
      label.cornerRadius = 20;
      label.thickness = 1;
      label.linkOffsetY = 30;

      // 确保 advancedTexture 已定义
      if (this.advancedTexture) {
        this.advancedTexture.addControl(label); // 添加标签到 advancedTexture
      }

      label.linkWithMesh(mesh); // 将标签与模型关联

      // 创建文本块
      var text1 = new TextBlock();
      text1.text = "这是警告"; // 设置文本为模型名称
      text1.color = "white";
      label.addControl(text1); // 将文本块添加到标签
    },
    createScene() {
      const canvas = document.createElement("canvas");
      this.$refs.canvasContainer.appendChild(canvas);
      const engine = new Engine(canvas, true);
      const scene = new Scene(engine);
      //this.advancedTexture = AdvancedDynamicTexture.CreateFullscreenUI("ui1");

     /* var sv = new ScrollViewer();
      sv.width = 0.4;
      sv.height = 0.4;
      sv.background = "#CCCCCC";

      this.advancedTexture.addControl(sv);

      var rc = new Rectangle();
      rc.thickness = 10;
      rc.width = 2;
      rc.height = 2;
      rc.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
      rc.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
      rc.color = "red";
      rc.background = "yellow";

      sv.addControl(rc);*/

      // 设置 canvas 的宽度和高度
      canvas.style.width = "100%";
      canvas.style.height = "100%";
      engine.resize();

      // 设置场景背景颜色为灰白色
      scene.clearColor = new Color3(0.8, 0.8, 0.8); // 灰白色

      // 使用 ArcRotateCamera
      const camera = new ArcRotateCamera(
        "camera",
        Tools.ToRadians(-120),
        Tools.ToRadians(70),
        5,
        new Vector3(0.0, 1.3, 0.0),
        scene
      );
      camera.minZ = 0.01; // 最小Z值
      camera.wheelDeltaPercentage = 0.01; // 鼠标滚轮缩放比例
      camera.upperRadiusLimit = 15; // 最大半径限制
      camera.lowerRadiusLimit = 0.5; // 最小半径限制
      camera.upperBetaLimit = Tools.ToRadians(85); // 最大Beta限制，防止穿过模型
      camera.lowerBetaLimit = Tools.ToRadians(0); // 最小Beta限制
      camera.attachControl(canvas, true); // 使相机控制与画布关联

      //var renderer = scene.enableDepthRenderer(camera, true);

      // 创建环境光
      const hemisphericLight = new HemisphericLight(
        "hemisphericLight",
        new Vector3(0, 1, 0),
        scene
      );
      hemisphericLight.intensity = 0.7; // 环境光强度

      // 创建多个点光源，降低强度
      const pointLight1 = new PointLight(
        "pointLight1",
        new Vector3(5, 5, 5),
        scene
      );
      pointLight1.intensity = 0.2; // 设置点光源强度

      const pointLight2 = new PointLight(
        "pointLight2",
        new Vector3(-5, 5, 5),
        scene
      );
      pointLight2.intensity = 0.2; // 设置点光源强度

      /*   const pointLight3 = new PointLight(
        "pointLight3",
        new Vector3(0, 5, -5),
        scene
      );
      pointLight3.intensity = 0.2; // 设置点光强度*/

      // 创建影生成器
      const shadowGenerator = new ShadowGenerator(1024, pointLight1);
      shadowGenerator.filter = ShadowGenerator.FILTER_PCF; // 设置阴影过滤器
      shadowGenerator.usePoissonSampling = true; // 使用泊松采样以获得更好的阴影质量

      // 创面
      const ground = MeshBuilder.CreateGround(
        "ground",
        { width: 10 * 20, height: 10 * 20 },
        scene
      ); // 创建地面
      ground.layerMask = 1; // 设置层掩码
      ground.receiveShadows = true; // 使地面接收阴影

      // 创建白色格子地板材质
      const groundMaterial = new StandardMaterial("groundMaterial", scene);
      groundMaterial.diffuseColor = new Color3(1, 1, 0.96); // 白色
      ground.material = groundMaterial;
      // 绘制网格线
      const gridSize = 20; // 网格的大小
      const step = 2; // 网格线之间的间隔

      // 创建水平线
      for (let i = -gridSize; i <= gridSize; i += step) {
        const line = MeshBuilder.CreateLines(
          `horizontalLine${i}`,
          {
            points: [
              new Vector3(-gridSize, 0, i), // 起点
              new Vector3(gridSize, 0, i), // 终点
            ],
          },
          scene
        );
        line.color = new Color3(0, 0, 0); // 设置线条颜色为黑色
      }

      // 创建垂直线
      for (let i = -gridSize; i <= gridSize; i += step) {
        const line = MeshBuilder.CreateLines(
          `verticalLine${i}`,
          {
            points: [
              new Vector3(i, 0, -gridSize), // 起点
              new Vector3(i, 0, gridSize), // 终点
            ],
          },
          scene
        );
        line.color = new Color3(0, 0, 0); // 设置线条颜色为黑色
      }

      // 使用 ImportMesh 加载两个 GLB 文件
      const modelPath = "https://cferp.top/dtct/";
      const modelName = "MD800mm-1.glb"; // 假设您有两个相同的模型

      SceneLoader.ImportMesh(
        "MD800mm-1",
        modelPath,
        modelName,
        scene,
        (meshes) => {
          const importedMesh1 = meshes[0]; // 获取第一个导入的网格
          console.log(importedMesh1); // 检查网格信息
          if (importedMesh1 && importedMesh1.isReady()) {
            importedMesh1.position = new Vector3(-1, 0, 0); // 将第一个模型放在场景中心左侧
            importedMesh1.scaling.z = Math.abs(importedMesh1.scaling.z); // 确保Z缩放为正数

            //this.createLabel(importedMesh1);
          }
        }
      );

      SceneLoader.ImportMesh(
        "MD800mm-1",
        modelPath,
        modelName,
        scene,
        (meshes) => {
          const importedMesh2 = meshes[0]; // 获取第二个导入的网格
          console.log(importedMesh2); // 检查网格信息
          if (importedMesh2 && importedMesh2.isReady()) {
            importedMesh2.position = new Vector3(1, 0, 0); // 将第二个模型放在场景中心右侧
            importedMesh2.scaling = new Vector3(1, 1, 1);
            importedMesh2.receiveShadows = true; // 使模型接收阴影
            shadowGenerator.getShadowMap().renderList.push(importedMesh2); // 将模型添加到阴影生成器
            //importedMesh2.scaling.z = Math.abs(importedMesh1.scaling.z);
            // 为模型启用阴影
            // importedMesh1.receiveShadows = true; // 使第一个模型接收阴影
            // importedMesh2.receiveShadows = true; // 使第二个模型接收阴影
            //  shadowGenerator.getShadowMap().renderList.push(importedMesh1); // 将第一个模型添加到阴影生成器
            //  shadowGenerator.getShadowMap().renderList.push(importedMesh2); // 将第二个模型添加到阴影生成器
            console.log("模型加载好了");

            //setTimeout(() => {
            // 在 (1, 0, 1) 位置创建一个红色的球体
            /*  const sphere = MeshBuilder.CreateSphere(
                "redSphere",
                { diameter: 0.5 },
                scene
              ); // 创建球体
              sphere.position = new Vector3(1, 1, 3); // 设置球体位置
              const sphereMaterial = new StandardMaterial(
                "sphereMaterial",
                scene
              ); // 创建材质
              sphereMaterial.diffuseColor = new Color3(0, 0, 1); // 设置材质颜色为红色
              sphere.material = sphereMaterial; // 将材质应用到球体

              sphere.computeWorldMatrix(true);
              console.log(`Is the ball ready? ${sphere.isReady()} `);*/
            // 获取球体的边界框信息
            //const boundingInfo = sphere.getBoundingInfo();
            //const boundingBoxBall = boundingInfo.boundingBox;*/

            // 计算边界框的八个顶点
            //const min = boundingBoxBall.minimum;
            //const max = boundingBoxBall.maximum;

            const min = importedMesh2.getHierarchyBoundingVectors().min;
            const max = importedMesh2.getHierarchyBoundingVectors().max;
            this.mymin = min;
            this.mymax = max;
            console.log("---------min-------");
            console.log(min);

            console.log("---------max-------");
            console.log(max);

            const vertices = [
              new Vector3(min.x, min.y, min.z), // 0
              new Vector3(max.x, min.y, min.z), // 1
              new Vector3(max.x, max.y, min.z), // 2
              new Vector3(min.x, max.y, min.z), // 3
              new Vector3(min.x, min.y, max.z), // 4
              new Vector3(max.x, min.y, max.z), // 5
              new Vector3(max.x, max.y, max.z), // 6
              new Vector3(min.x, max.y, max.z), // 7
            ];

            console.log("alartX:" + this.alartX.toString());
            console.log("alartY:" + this.alartY.toString());
            console.log("alartZ:" + this.alartZ.toString());
            // 创建边界框的线条
            const lines = [];
            const edges = [
              [0, 1],
              [1, 2],
              [2, 3],
              [3, 0], // 底面
              [4, 5],
              [5, 6],
              [6, 7],
              [7, 4], // 顶面
              [0, 4],
              [1, 5],
              [2, 6],
              [3, 7], // 侧面
            ];

            edges.forEach((edge) => {
              lines.push(vertices[edge[0]]);
              lines.push(vertices[edge[1]]);
            });

            // 使用 LinesBuilder 创建线条
            const boundingBoxLines = MeshBuilder.CreateLines(
              "boundingBoxLines",
              { points: lines },
              scene
            );
            boundingBoxLines.color = new Color3(0, 1, 0); // 绿色线条
            // }, 1); // 延迟 100 毫秒

            //按钮测试
            /*var advancedTexture = AdvancedDynamicTexture.CreateFullscreenUI("UI");

            var button = Button.CreateSimpleButton("but", "Click Me");
            button.width = 0.2;
            button.height = "40px";
            button.color = "white";
            button.background = "green";
            advancedTexture.addControl(button); */

            //在第二个框上画气泡按钮

            // 创建 GUI 管理器
            try {
              // 创建 SpriteManager
              const spriteManager = new SpriteManager(
                "spriteManager",
                "models/alart.png",
                2,
                800,
                scene
              );

              spriteManager.isPickable = true;
              // 创建一个 Sprite
              const sprite = new Sprite("mySprite", spriteManager);

              console.log("--------9999--------");
              console.log(this.mymin);

              this.alartX = (this.mymin.x + this.mymax.x) / 2;
              this.alartY = 2.2;
              this.alartZ = (this.mymin.z + this.mymax.z) / 2;

              sprite.position = new Vector3(
                this.alartX,
                this.alartY,
                this.alartZ
              );
              //sprite.position = new Vector3(this.alartX, this.alartY, this.alartZ); // 设置 Sprite 的置
              sprite.size = 0.5; // 设置 Sprite 的大小
              //sprite.width=1;
              //sprite.height =1;
              sprite.isPickable = true;

              scene.onPointerObservable.add((eventData) => {
                if (eventData.type === PointerEventTypes.POINTERDOWN) {
                  const pickResult = scene.pickSprite(
                    scene.pointerX,
                    scene.pointerY
                  );
                  if (pickResult && pickResult.hit) {
                    console.log(
                      "Clicked Sprite:",
                      pickResult.pickedSprite.name
                    ); // Prints the sprite name
                    this.showModalPopup();
                  }
                }
              }, PointerEventTypes.POINTERDOWN);
              console.log(" okokokok=========！");
            } catch (error) {
              console.error("创建 GUI 控件时发生错误:", error);
            }
          }
        }
      );

      // 渲染循环
      engine.runRenderLoop(() => {
        scene.render();
        //console.log("Rendering..."); // 确保渲染循环正常工作
      });

      // 处理窗口调整
      window.addEventListener("resize", () => {
        engine.resize();
      });

      // 创建坐标轴
      this.createAxes(scene);

      // 创建四面墙
      const wallHeight = 4; // 墙的高度
      const wallThickness = 0.1; // 墙的厚度
      const wallLength = 16; // 墙的长度（边长为9，面积为81）

      // 创建前墙
      const frontWall = MeshBuilder.CreateBox(
        "frontWall",
        {
          height: wallHeight,
          width: wallThickness, // 墙的厚度
          depth: wallLength, // 墙的长度
        },
        scene
      );
      frontWall.position = new Vector3(-8, 0, 0); // 设置前墙位置

      // 创建后墙
      const backWall = MeshBuilder.CreateBox(
        "backWall",
        {
          height: wallHeight,
          width: wallThickness, // 墙的厚度
          depth: wallLength,
        },
        scene
      );
      backWall.position = new Vector3(8, 0, 0); // 设置后墙位置

      // 创建左墙
      const leftWall = MeshBuilder.CreateBox(
        "leftWall",
        {
          height: wallHeight,
          width: wallLength, // 墙的长度
          depth: wallThickness, // 墙的厚度
        },
        scene
      );
      leftWall.position = new Vector3(0, 0, 8); // 设置左墙位置

      // 创建右墙
      const rightWall = MeshBuilder.CreateBox(
        "rightWall",
        {
          height: wallHeight,
          width: wallLength, // 墙的长度
          depth: wallThickness, // 墙的厚度
        },
        scene
      );
      rightWall.position = new Vector3(0, 0, -8); // 设置右墙位置

      // 创建墙的材质并设置为白色
      const wallMaterial = new StandardMaterial("wallMaterial", scene);
      wallMaterial.diffuseColor = new Color3(1, 1, 1); // 设置为纯白色

      // 应用材质到所有墙
      frontWall.material = wallMaterial;
      backWall.material = wallMaterial;
      leftWall.material = wallMaterial;
      rightWall.material = wallMaterial;
    },
    createAxes(scene) {
      const axisLength = 5; // 坐标轴长度
      const scaleInterval = 1; // 刻度间隔

      // X轴（红色）
      const xAxis = MeshBuilder.CreateLines(
        "xAxis",
        {
          points: [new Vector3(0, 0, 0), new Vector3(axisLength, 0, 0)],
        },
        scene
      );
      xAxis.color = new Color3(1, 0, 0); // 红色

      // Y轴（绿色）
      const yAxis = MeshBuilder.CreateLines(
        "yAxis",
        {
          points: [new Vector3(0, 0, 0), new Vector3(0, axisLength, 0)],
        },
        scene
      );
      yAxis.color = new Color3(0, 1, 0); // 绿色

      // Z轴（蓝色）
      const zAxis = MeshBuilder.CreateLines(
        "zAxis",
        {
          points: [new Vector3(0, 0, 0), new Vector3(0, 0, axisLength)],
        },
        scene
      );
      zAxis.color = new Color3(0, 0, 1); // 蓝色

      // 添加刻度
      for (let i = scaleInterval; i < axisLength; i += scaleInterval) {
        // X轴刻度
        const xTick = MeshBuilder.CreateLines(
          "xTick" + i,
          {
            points: [new Vector3(i, 0.1, 0), new Vector3(i, -0.1, 0)],
          },
          scene
        );
        xTick.color = new Color3(1, 0, 0); // 红色

        // Y轴刻度
        const yTick = MeshBuilder.CreateLines(
          "yTick" + i,
          {
            points: [new Vector3(0, i, 0.1), new Vector3(0, i, -0.1)],
          },
          scene
        );
        yTick.color = new Color3(0, 1, 0); // 绿色

        // Z轴刻度
        const zTick = MeshBuilder.CreateLines(
          "zTick" + i,
          {
            points: [new Vector3(0.1, 0, i), new Vector3(-0.1, 0, i)],
          },
          scene
        );
        zTick.color = new Color3(0, 0, 1); // 蓝色
      }
    },
  },
};
</script>

<style scoped>
/* 添加样式以确保画布适应容器 */
.hello {
  width: 100%;
  height: 100vh; /* 或者您想要的高度 */
  position: relative; /* 确保子元素可以绝对定位 */
}
</style>
















